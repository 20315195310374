.layout-header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0.45rem;
	box-sizing: border-box;
	padding: 0.12rem 0.16rem;
	font-size: 0.16rem;
	font-weight: bold;
	color: #232323;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
}
.left-style {
	padding: 0.08rem;
	display: flex;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	left: 0.12rem;
	width: 0.14rem;
	height: 0.14rem;
}
.title-style {
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: calc(100% - 1.07rem);
}
.right-style {
	position: absolute;
	right: 0.12rem;
}
